<!--
 * @Author: 王时霞
 * @Date: 2023-09-20 09:50:18
 * @LastEditors: 王时霞
 * @LastEditTime: 2024-01-17 16:53:28
 * @Description: file content
 * @FilePath: \yuyue.keenkq.com\src\views\wx_work\components\SelectPro.vue
-->
<template>
<div>
  <van-field
    v-model="value"
    required
    :rules="[{ required: true, message:  `清选择${title}` }]"
    :label="title"
    :placeholder="`${title}`"
    readonly
  >
    <template #button>
      <van-button size="small" type="primary" @click="show=true" native-type="button">选择{{title}}</van-button>
    </template>
  </van-field>
  <van-popup
    v-model="show"
    closeable
    close-icon="close"
    position="bottom"
    :style="{ height: '100%'}"
  >
    <div class="popup_top">
      <div class="title">
        选择{{title}}
      </div>
      <form action="/">
        <van-search
          v-model="keywords"
          shape="round"
          background="#38b6ab"
          @search="$emit('onSearch',keywords)"
          :placeholder="placeholder"
        >
        </van-search>
      </form>

    </div>
    
    <van-list class="selectList">
      <van-cell v-for="item in options" :key="item.id" :title="item.name" :value="item.sjhm" @click="onSelect(item)"/>
    </van-list>
  </van-popup>

</div>
  
  
</template>
<script>
export default {
  name: "SelectPro",
  props: ["value", "title", "options", "form","placeholder"],
  data() {
    return {
      show: false,
      keywords:''
    };
  },
  methods: {
    onSelect(item) {
      this.show = false;
      this.$emit("onSelect", item);
    },
    getData() {
      this.show = true;
      this.$emit("getData");
    },
  },
};
</script>
<style scoped>

.selectList .van-cell__value{
  text-align:left;
  min-width:50%;
}
</style>


