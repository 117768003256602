<template>
  <div>
    <van-field 
      v-model="value" 
      :label="label" 
      required 
      :placeholder="`请选择${label}`"
      @click="show = true" 
      :rules="[{ required: true, message: `请选择${label}` }]" 
      readonly 
      is-link
    />
    <van-action-sheet v-model="show" :actions="options" @select="onSelect" />
  </div>
</template>
<script>
export default {
  props:{
    options:Array,
    label:String,
    value:String,
  },
  data() {
    return {
      show:false,
    };
  },
  methods:{
    onSelect(item){
      console.log(item)
      this.show = false
      this.$emit('onSelect',item)
    }
  }
};
</script>
<style>
.no-style{
  padding:0px;
  margin:0px;
}
</style>


