<!--
 * @Author: 王时霞
 * @Date: 2021-12-04 16:35:24
 * @LastEditors: 王时霞
 * @LastEditTime: 2024-03-27 13:29:33
 * @Description: file content
 * @FilePath: \yuyue.keenkq.com\src\components\WxWorkFooter.vue
-->
<template>
  <div class='footer_bottom'>
    <!-- <mu-container :value="shift"  shift class="index_bottom">
      <mu-bottom-nav color="indigo500">
        <mu-bottom-nav-item  color="indigo500" value="jiandang" title="建档" icon="content_paste" active-class='sle' :to="{name:'HisAddPatient'}"></mu-bottom-nav-item>
          <mu-bottom-nav-item  color="indigo500" value="yuyue" title="建档预约" icon="assignment" active-class='sle' :to="{name:'Daiyuyue'}"></mu-bottom-nav-item>
          <mu-bottom-nav-item value="my" title="预约记录" icon="account_box"  active-class='sle' :to="{name:'WxWorkOrderList'}"></mu-bottom-nav-item>
          <mu-bottom-nav-item value="user" title="用户提交页面" icon="people"  active-class='sle' :to="{name:'Appointment',query:{from_user:user_id}}"></mu-bottom-nav-item>
      </mu-bottom-nav>
      
    </mu-container> -->
    <van-tabbar v-model="active" route active-color="#07c160" inactive-color="#999">
      <van-tabbar-item name="jiandang" replace :to="{name:'HisAddPatient'}" icon="friends">建档</van-tabbar-item>
      <van-tabbar-item name="yuyue" :to="{name:'Daiyuyue'}" icon="bookmark">建档预约</van-tabbar-item>
      <van-tabbar-item name="my" :to="{name:'WxWorkOrderList'}" icon="column">预约记录</van-tabbar-item>
    </van-tabbar>

  </div>
</template>
<script>


export default {
  name: "Home",
  props: {
    shift:String
  },
  components: {
    
  },
  data() {
    return {
      user_id:'',
      active:''
      
    };
  },
  created() {
    this.user_id = localStorage.user_id
    
  },
  methods: {
    
  },
 
};
</script>
<style>
/* .footer_bottom{
  background-color: white;
  position:fixed;
  bottom:0px;
  left:0px;
  right:0px;
  margin:0px auto;
  z-index:99;

} */
/* .van-tabbar{
  height:75px;
} */
.van-tabbar-item{
  /* font-size:14px !important; */
  font-weight:500;
}
.index_bottom{
  margin:10px auto 20px auto;
  
}
.index_bottom .router-link-exact-active {
    padding-top: 6px;
    padding-bottom: 5px;
    color: #3f51b5;
}
</style>
